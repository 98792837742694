import * as React from "react"

import HomeSecurityContent from "../components/home-security-content"
import Seo from "../components/seo"

const HomeSecurity = () => (
  <div>
    <Seo title="Home Security" />
    <HomeSecurityContent/>
  </div>
)

export default HomeSecurity
