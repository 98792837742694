import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import Nav from "./nav-services"
// import Masthead from "./masthead"
import Products from "./products"
import ProductsCarousel from "./products-carousel"
import ContactUs from "./contact-us"
import Footer from "./footer"

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]');
}


const HomeSecurityContent = ({ siteTitle }) => (
  <div class="home-security">
    <Nav/>
    <div className="masthead-bg">
      <div className="container m-auto">
        <div className="w-full pl-4 pt-14 pb-8 lg:w-3/5 md:w-1/2
        md:pt-32 lg:pt-20 xl:pt-32
        md:mt-4  lg:mt-8  xl:mt-8  2xl:mt-16 
        md:pb-24 lg:pb-64 xl:pb-80 2xl:pb-96">
          <div className="home-security-masthead-label">
            QOI HOME SECURITY
          </div>
          <div className="masthead-headline animate__animated animate__fadeInLeft">
          Protect your home <br/>
and the precious <br/>
things inside it
          </div>
        </div>
      </div>
    
    </div>
    <div className="flex">
      <div className="services-left w-full lg:w-2/5 place-content-center flex flex-col">
        <div className="">
          <div className="services-title">
            ALARM SYSTEM
          </div>
          <div className="services-tagline">
          More intelligent. <br/>
          More secured.
          </div>
          <div className="p-0 m-0 leading-0 lg:w-0 lg:hidden">
            <StaticImage src="../images/alarm-system.jpg" className=" w-full" alt="Alarm System"/>
          </div>
          <div className="service-body">
          Designed to detect intrusion to give you peace of mind. Front door left unlocked? No worries as you’ll get alerts 24/7 for possible emergencies.
          </div>
        </div>
      </div>

      <div className="w-0 lg:w-3/5">
        <div className="p-0 m-0 leading-0">
          <StaticImage src="../images/alarm-system.jpg" className=" w-full" alt="Alarm System"/>
        </div>
      </div>

    </div>


    <div className="flex">

      <div className="w-0 lg:w-3/5">
        <div className="p-0 m-0 leading-0">
          <StaticImage src="../images/camera-system.jpg" className=" w-full" alt="Camera System"/>
        </div>
      </div>

      <div className="services-right w-full lg:w-2/5">
        <div className="">
          <div className="services-title">
          CAMERA SYSTEM
          </div>
          <div className="services-tagline">
          Keep an eye 
          on your home anytime, anywhere.
          </div>
          <div className="p-0 m-0 leading-0 lg:w-0 lg:hidden">
            <StaticImage src="../images/camera-system.jpg" className=" w-full" alt="Camera System"/>
          </div>
          <div className="services-body">
          View your security camera whenever you want, where you are. Safeguarding you and the things that matter.
          </div>
        </div>
      </div>

    </div>    



    <div className="flex">
      <div className="wd-full lg:w-2/5 services-left">
        <div className="">
          <div className="services-title">
          24-HOUR MONITORING
          </div>
          <div className="services-tagline">
          Security for every second of the day
          </div>
          <div className="p-0 m-0 leading-0 lg:w-0 lg:hidden">
            <StaticImage src="../images/24-hour-monitoring.jpg" className=" w-full" alt="Networking"/>
          </div>
          <div className="services-body">
          No need to constantly worry about your home! Armed and disarmed, our 24-hour monitoring systems help you spot suspicious activity and take action.
          </div>
        </div>
      </div>

      <div className="w-0 lg:w-3/5">
        <div className="p-0 m-0 leading-0">
          <StaticImage src="../images/24-hour-monitoring.jpg" className=" w-full" alt="Networking"/>
        </div>
      </div>

    </div>
    
    <Products/>
    <ProductsCarousel/>

    <div className="text-center py-8">
      <div className="text-3xl font-saira">
      We Value What You Value
      </div>
      <div className="">
        <div className="w-8 border-t-8 my-6 border-accent inline-block">

        </div>
        <div className="mb-8">
        We’ve got everything you’ll need to make the most out of your smart home.
        </div>
      </div>


    </div>

    <div className="md:flex">
      <div className="w-full md:w-1/2">
        <div className="p-0 m-0 leading-0 opacity-50 hover:opacity-100">
          <a href="/home-technology">

          <StaticImage src="../images/btn-home-technology.png" className="w-full" alt="Home Technology"/>
          </a>
        </div>
      </div>
      <div className="w-full md:w-1/2">
        <div className="p-0 m-0 leading-0 opacity-50 hover:opacity-100">
          <a href="https://www.qoismartshades.com/" target="_blank">
          <StaticImage src="../images/btn-smart-shades.png" className="w-full" alt="Smart Shades"/></a>
        </div>
      </div>
    </div>
    <ContactUs/>
    <Footer/>
  </div>
)

export default HomeSecurityContent